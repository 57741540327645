import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./utils/rem.js";
import 'normalize.css/normalize.css'
import './assets/css/common.less'
import './assets/font/iconfont.css'
// import VueAwesomeSwiper from 'vue-awesome-swiper'
// import 'swiper/swiper-bundle.css'

// Vue.use(VueAwesomeSwiper, /* { default options with global component } */)
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
