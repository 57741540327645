<template>
<a class="item" :style="{backgroundImage: bgUrl}" :href="platform.link" @mouseover="handleMouseOver" @mouseout="handleMouseOut">
  <div class="code-content" v-if="platform.code_img.data">
    <div class="code-item">
      <div class="code-img" :style="{backgroundImage: 'url(' + platform.code_img.data.attributes.url +')'}"></div>
      <div class="code-title">{{ platform.text }}</div>
    </div>
  </div>
</a>
</template>

<script>
export default {
  name: 'PlatformItem',
  components: { // 组件的引用

  },
  props: {
    platform: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      bgUrl: `url(${this.platform.icon.data.attributes.url })`,
    }
  },
  created () { // 实例被创建之后执行代码

  },
  computed: { // 计算属性

  },
  methods: { // 方法
    handleMouseOver() {
      console.log(this.platform);
      this.bgUrl = `url(${this.platform.icon_active.data.attributes.url })`;
      console.log(this.bgUrl)
    },
    handleMouseOut() {
      this.bgUrl = `url(${this.platform.icon.data.attributes.url })`;
    },
  },
  mounted () { // 页面进入时加载内容

  },
  watch: { // 监测变化

  }
}
</script>
<style lang='less'>

</style>