<template>
  <div class="block block-1">
    <div class="block-1-content">
      <div class="title">{{ viewData.title }}</div>
      <!-- <img
        alt="加速器"
        class="block-1-img"
        :src="viewData.img.data.attributes.url"
      /> -->
      <div class="speed">{{ viewData.speed }}</div>
      <a
        class="download-btn"
        :href="viewData.download.link"
        ><i :style="{backgroundImage: 'url('+ viewData.download.icon.data.attributes.url +')'}"></i> {{ viewData.download.text }}
      </a>
      <div class="support">
        <div class="title">{{ viewData.platform }}</div>
        <div class="gap"></div>
        <div class="icon-list">
          <a
            class="item"
            v-for="item in viewData.platformlist"
            :key="item.text"
            :href="item.link"
            ><i :style="{backgroundImage: 'url('+ item.icon.data.attributes.url +')'}"></i><span>{{ item.text }}</span></a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getData } from '@/api/index.js'
export default {
  name: 'BlockOne',
  data () {
    return {
      viewData: {}
    }
  },
  created () { // 实例被创建之后执行代码
    this.getData({
      url: '/api/home-part-first-info?populate[1]=img.url&populate[2]=download&populate[3]=download.icon&populate[4]=platformlist&populate[5]=platformlist.icon',
    })
  },
  computed: { // 计算属性

  },
  components: { // 组件的引用

  },
  methods: { // 方法
    async getData(options){
      let { data } = await getData(options)
      this.viewData = data.data.attributes
    }
  },
  mounted () { // 页面进入时加载内容
  },
  watch: { // 监测变化

  }
}
</script>
<style lang='less' scoped>
.block-1 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 150px;
  position: relative;
  z-index: 99;
  .block-1-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 1200px;
    .query-mixin(1200px, {
      width: 100%;
      // padding: 0 20px;
    });
    .title {
      font-size: 50px;
      font-weight: 600;
      color: #fff;
      .query-mixin(768px, {
        font-size: 24px;
        margin-top: 20px;
      });
    }
    .speed{
      position: relative;
      margin-top: 20px;
      width: 308px;
      height: 58px;
      text-align: center;
      font-size: 34px;
      font-weight: 600;
      color: #FFD700;
      .query-mixin(768px, {
        width: 150px;
        height: 30px;
        font-size: 16px;
        margin-top: 30px;
      });
    }
    .speed::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 2px;
      background: #FFD700;
      // background-image: linear-gradient(to right, rgb(248, 204, 5), rgb(51, 50, 50));
    }
    .block-1-img {
      margin-top: 20px;
      width: 298px;
      height: 58px;
    }
    .download-btn {
      margin-top: 85px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 250px;
      height: 60px;
      background: @theme-color;
      border-radius: 30px;
      font-size: 21px;
      font-weight: 400;
      color: #fff;
      cursor: pointer;
      .query-mixin(768px, {
        margin-top: 30px;
        height: 48px;
        margin-top: 40px;
      });
      i {
        margin: 0 17px 0 30px;
        width: 29px;
        height: 29px;
        background: 0 0/100% 100% no-repeat;
      }
    }
    .support {
      margin-top: 140px;
      width: 100%;
      .query-mixin(991px, {
        margin-top: 110px;
      });
      .query-mixin(768px, {
        margin-top: 60px;
        display: none;
      });
      .title {
        font-size: 24px;
        font-weight: 700;
        text-align: center;
        color: #c0c0c6;
        line-height: 24px;
      }
      .gap {
        margin-top: 24px;
        // width: 1196px;
        height: 1px;
        background: #313134;
      }
      .icon-list {
        display: flex;
        justify-content: space-between;
        margin-top: 22px;
        padding: 0 133px;
        width: 100%;
        .item {
          cursor: pointer;
          display: flex;
          flex-direction: column;
          align-items: center;
          color: #8f9097;
          flex: 1;
          i {
            background:
              0 0/100% 100% no-repeat;
            width: 40px;
            height: 40px;
          }
          span {
            text-align: center;
          }
        }
      }
    }
  }
}
</style>