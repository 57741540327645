<template>
  <div class="block block-2">
    <div class="sub-title">{{viewData.title}}</div>
    <div class="sub-des">
      <p>
        {{viewData.content}}
      </p>
    </div>
    <div class="card-list">
      <!---->
      <div class="card-item" :class="activeIndex===index && 'active'" v-for="(item,index) in viewData.list" :key="item.title">
        <div class="item-title">{{item.title}}</div>
        <div class="item-content">
          {{item.content}}
        </div>
      </div>
    </div>
    <!-- <swiper ref="mySwiper" :options="swiperOptions">
      <swiper-slide v-for="item in viewData.carousel" :key="item.img.data.attributes.url">
        <img class="item" :src="item.img.data.attributes.url" alt="">
      </swiper-slide>
    </swiper> -->
  </div>
</template>

<script>
import { getData } from '@/api/index.js'
// import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css';
export default {
  name: 'BlockTow',
  components: { // 组件的引用
    // Swiper,
    // SwiperSlide
  },
  data () {
    return {
      // banners: [ 'https://www.qiyou.cn/assets/images/index/swiper-img3.png', 'https://www.qiyou.cn/assets/images/index/swiper-img2.png', 'https://www.qiyou.cn/assets/images/index/swiper-img1.png' ],
      // 配置参数
      swiperOptions: {
        pagination: {
          el: '.swiper-pagination'
        },
        loop: true,
        autoplay: {
          disableOnInteraction: false,
          delay: 3000,
        }
        // Some Swiper option/callback...
      },
      activeIndex: 0,
      viewData: {}
    }
  },
  created () { // 实例被创建之后执行代码
    this.getData({
      url: '/api/home-part-second-info?populate[1]=list&populate[2]=carousel&populate[3]=carousel.img',
    })
  },
  computed: { // 计算属性
    // 计算属性拿到swiper实例
    swiper() {
      // this.$refs.mySwiper拿到Swiper vue组件实例，
      // 而它身上挂载的$swiper就是swiper实例，可以通过swiper实例来操作slider。
      return this.$refs.mySwiper.$swiper
    }
  },
  methods: { // 方法
    async getData(options){
      let { data } = await getData(options)
      console.log(data)
      this.viewData = data.data.attributes
    },
    changeActive(){
      setTimeout(() => {
        if (this.activeIndex < 2) {
          this.activeIndex++
        }else{
          this.activeIndex = 0
        }  
        this.changeActive()
      }, 5000);
    }
  },
  mounted () { // 页面进入时加载内容
    this.changeActive()
  },
  watch: { // 监测变化

  }
}
</script>
<style lang='less'>
.block-2 {
  // width: 1200px;
  margin-top: 120px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  .query-mixin(768px, {
    width: 100%;
    margin-top: 140px;
  });

  .card-list{
    // width: 1200px;
    margin-top: 42px;
    display: flex;
    justify-content: space-between;
    .query-mixin(768px, {
      display: none;
      width: 100%;
    });
    .card-item{
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      align-items: flex-start;
      box-sizing: border-box;
      width: 380px;
      height: 160px;
      padding: 33px 20px 0;
      background: #242428;
      border-radius: 15px;
      cursor: pointer;
      &:hover{
        background: #454548;
        border: 2px solid #acabab;
        box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.5);
      }
      .item-title{
        font-size: 20px;
        font-weight: 400;
        color: #fff;
        line-height: 20px;
      }
      .item-content{
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        margin-top: 19px;
        font-size: 14px;
        color: #999;
        line-height: 26px;
        text-align: left;
      }
    }
    .active{
      border: 2px solid #acabab;
      box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.5);
      background: #454548;
    }
  }
  .swiper-container{
    width: 100%;
  }
}
</style>