<template>
	<div id="header">
		<div class="pc-header">
			<a
				class="logo"
				href="/"
				:style="{
					backgroundImage:
						'url(' + viewData.logo.data.attributes.url + ')',
				}"
			></a>
			<nav-bar></nav-bar>
			<div class="login">
				<div class="insert">
					<a
						:href="viewData.btn.link"
						class="header-user"
					>
						<i
							:style="{
								backgroundImage:
									'url(' + viewData.btn.icon.data.attributes.url + ')',
							}"
						></i>
						免费体验
					</a>
				</div>
			</div>
		</div>
    <div class="mobile-header-container">
      <div class="mobile-header">
			<a
				class="logo"
				href="/"
				:style="{
					backgroundImage:
						'url(' + viewData.logo.data.attributes.url + ')',
				}"
			></a>
			<div class="hamburger">
        <div v-if="!isOpen" class="open iconfont icon-HAMBURGERMENU" @click="changeOpen"></div>
        <div v-else class="close iconfont icon-close" @click="changeOpen"></div>
        <transition name="fade">
          <nav-bar v-show="isOpen"></nav-bar>
        </transition>
			</div>
		</div>
    </div>
	</div>
</template>

<script>
import { getData } from "@/api/index.js";
import NavBar from "@/components/nav/NavBar.vue";
export default {
	name: "HeaderView",
	components: {
		// 组件的引用
		NavBar,
	},
	data() {
		return {
      isOpen: false,
			viewData: {},
		};
	},
	created() {
		// 实例被创建之后执行代码
		this.getData({
			url: "/api/header?populate=logo&populate=btn&populate=btn.icon",
		});
	},
	computed: {
		// 计算属性
	},
	methods: {
		// 方法
		async getData(options) {
			let { data } = await getData(options);
			this.viewData = data.data.attributes;
			console.log(this.viewData);
			this.iconurl = this.viewData.icon.data.attributes.url;
			console.log(this.iconurl);
		},
    changeOpen() {
      this.isOpen = !this.isOpen
    }
	},
	mounted() {
		// 页面进入时加载内容
	},
	watch: {
		// 监测变化
	},
};
</script>
<style lang="less">
#header {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 90px;
	position: fixed;
	top: 0;
	z-index: 999;
	background: #000;
	transition: all 0.3s linear;
  .query-mixin(768px, {
    height: 54px;
  });
	.pc-header {
		width: 1190px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		.query-mixin(768px,{
			display: none;
		});
		.logo {
			flex-shrink: 0;
			width: 124px;
			height: 45px;
			background: 0 0/100% no-repeat;
			display: block;
		}
		.login {
			display: flex;
			justify-content: flex-end;
			align-items: center;
			text-align: center;
			height: 90px;
			.insert {
				display: flex;
				align-items: center;
				a {
					display: flex;
					justify-content: flex-start;
					align-items: center;
					width: 135px;
					height: 40px;
					background: @theme-color;
					border-radius: 20px;
					font-size: 14px;
					font-weight: 400;
					color: #fff;
					cursor: pointer;
				}
				i {
					margin: 0 13px 0 25px;
					width: 13px;
					height: 16px;
					background: 0 0/100% no-repeat;
				}
			}
		}
	}
  .mobile-header-container{
    display: none;
    width: 94%;
    height: 100%;
    margin: 0 auto;
    .query-mixin(768px, { 
      display: block;
    });
    .mobile-header {
      height: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      .logo {
        flex-shrink: 0;
        width: 100px;
        height: 38px;
        background: 0 0/100% no-repeat;
        display: block;
      }
      .hamburger{
        width: 25px;
        display: flex;
        align-items: center;
        color: #fff;
        // position: relative;
        .open{
          font-size: 28px;
        }
        .close{
          font-size: 24px;
        }
      }
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.4s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

// @media screen and (max-width: 768px) {
//   .pc-header{
//     display: none;
//   }
// }
</style>
