<template>
<div class="card-list">
  <a 
  v-for="item in list"
  :key="item.link"
  :href="item.link" target="_blank" title=""
    ><img
      alt="加速器"
      class="game-img"
      loading="lazy"
      :src="item.img.data.attributes.url" /></a
  >
</div>
</template>

<script>
export default {
  name: 'CardList',
  components: { // 组件的引用

  },
  props: {
    list: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {

    }
  },
  created () { // 实例被创建之后执行代码

  },
  computed: { // 计算属性

  },
  methods: { // 方法

  },
  mounted () { // 页面进入时加载内容

  },
  watch: { // 监测变化

  }
}
</script>
<style lang='less'>
	.card-list {
		display: flex;
		margin-top: 42px;
    width: 100%;
    .query-mixin(768px, {
      flex-wrap: wrap;
      justify-content: center;
    });
		a {
      .query-mixin(992px, {
        flex: 1;
        display: flex;
        justify-content: center;
      });
			img {
				transition: all 0.2s ease-out;
				width: 220px;
				height: 330px;
				margin-right: 18px;
        border-radius: 10px;
        .query-mixin(992px, {
          width: 140px;
          height: 210px;
        });
        .query-mixin(768px, {
          margin-right: 0;
          width: 106px;
          height: 158px;
          margin-bottom: 23px;
          border-radius: 4px;
        });
				&:hover {
					transform: scale(1.07);
				}
			}
		}
	}
</style>