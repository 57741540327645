import { render, staticRenderFns } from "./BlockOne.vue?vue&type=template&id=74516a4c&scoped=true&"
import script from "./BlockOne.vue?vue&type=script&lang=js&"
export * from "./BlockOne.vue?vue&type=script&lang=js&"
import style0 from "./BlockOne.vue?vue&type=style&index=0&id=74516a4c&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/.pnpm/registry.npmmirror.com+vue-loader@15.10.1_ln4ufljkb6jcdvhyz3edowuorm/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "74516a4c",
  null
  
)

export default component.exports