import axios from "axios";
import { baseUrl } from '@/utils/base_url'
console.log('baseUrl')
console.log(baseUrl);
// import Vue from "vue";
// import store from "../store";
// const getBaseUrl = (baseUrl,env) => {
//   let url = {
//     production: "/",
//     development: "http://10.151.121.16:1337/",
//     // development: "http://localhost:1337/",
//     // development: "/",
//     test: "/",
//   }[env];
//   if (!url) {
//     url = "/";
//   }
//   return url;
// };

const getBaseUrl = (env) => {
  let url = {
    production: 'prod',
    development: 'dev',
    // development: "http://localhost:1337/",
    // development: "/",
    test: 'test',
  }[env];
  if (!url) {
    url = "/";
  }
  return url;
};


class Axios {
  constructor() {
    this.urlEnv = getBaseUrl(process.env.NODE_ENV);
    // this.timeout = 5000;
    this.withCredentials = true;
  }
  setInterceptors = (instance) => {
    instance.interceptors.request.use(
      (config) => {
        // if (store.getters.token) {
        //   config.headers.Authorization = "Bearer " + store.getters.token;
        // }
        return config;
      },
      (err) => Promise.reject(err)
    );
    instance.interceptors.response.use(
      (res) => {
        // if (res.data.code !== 0 && res.data.msg) {
        //   Vue.prototype.$message({
        //     type: "error",
        //     message: res.data.msg,
        //   });
        // }
        return res;
      },
      (err) => {
        if (err.response) {
          // if (err.response.data.msg) {
          //   Vue.prototype.$message({
          //     type: "error",
          //     message: err.response.data.msg,
          //   });
          // }
        } else {
          // if (!window.navigator.online) {
          //   // 断网处理
          //   // todo: jump to offline page
          //   Vue.prototype.$message({
          //     type: "error",
          //     message: "请注意检查网络",
          //   });
          // }
        }
        return Promise.reject(err);
      }
    );
  };
  request(options) {
    const instance = axios.create();
    const config = {
      ...options,
      baseURL: options.baseURL ? options.baseURL[this.urlEnv] : baseUrl[this.urlEnv],
      // timeout: this.timeout,
    };
    this.setInterceptors(instance);
    return instance(config);
  }
}
export default new Axios();
