var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"header"}},[_c('div',{staticClass:"pc-header"},[_c('a',{staticClass:"logo",style:({
					backgroundImage:
						'url(' + _vm.viewData.logo.data.attributes.url + ')',
				}),attrs:{"href":"/"}}),_c('nav-bar'),_c('div',{staticClass:"login"},[_c('div',{staticClass:"insert"},[_c('a',{staticClass:"header-user",attrs:{"href":_vm.viewData.btn.link}},[_c('i',{style:({
								backgroundImage:
									'url(' + _vm.viewData.btn.icon.data.attributes.url + ')',
							})}),_vm._v(" 免费体验 ")])])])],1),_c('div',{staticClass:"mobile-header-container"},[_c('div',{staticClass:"mobile-header"},[_c('a',{staticClass:"logo",style:({
					backgroundImage:
						'url(' + _vm.viewData.logo.data.attributes.url + ')',
				}),attrs:{"href":"/"}}),_c('div',{staticClass:"hamburger"},[(!_vm.isOpen)?_c('div',{staticClass:"open iconfont icon-HAMBURGERMENU",on:{"click":_vm.changeOpen}}):_c('div',{staticClass:"close iconfont icon-close",on:{"click":_vm.changeOpen}}),_c('transition',{attrs:{"name":"fade"}},[_c('nav-bar',{directives:[{name:"show",rawName:"v-show",value:(_vm.isOpen),expression:"isOpen"}]})],1)],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }