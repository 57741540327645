<template>
	<div class="block block-3">
		<div class="top">
			<span class="sub-title">{{viewData.title}}</span
			><a class="btn" :href="viewData.more_link">{{viewData.more}}</a>
		</div>
		<div class="sub-des">
			<p>
				{{viewData.content}}
			</p>
		</div>
    <card-list :list="viewData.list"></card-list>
    <!-- <notice :view-data="viewData"></notice> -->
    <!-- <information-box :list="infoData"></information-box> -->
	</div>
</template>

<script>
import { getData } from '@/api/index.js'
import CardList from "@/components/card_list/CardList.vue";
// import Notice from "@/components/notice/Notice.vue";
// import InformationBox from "@/components/information/Information.vue";
export default {
	name: "BlockThree",
	components: {
		// 组件的引用
    CardList,
    // Notice,
    // InformationBox,
	},
	data() {
		return {
      list: [
      ],
      viewData: {},
			infoData: [],
    };
	},
	created() {
		// 实例被创建之后执行代码
    this.getData({
			url: '/api/home-part-third-info?populate=list&populate=list.img&populate=notice_bg&populate=list.img&populate=notice_btn&populate=notice_btn.icon'
      // url: '/api/home-part-third-info?populate=list&populate=list.img&populate=notice_bg&populate=notice_btn&populate=notice_btn.icon&populate=info_list',
    })
		this.getInfoList({
			url: '/api/articles?filters[category][id][$eq]=1&fields=title&sort=desc&&sort=id:DESC&pagination[page]=1&pagination[pageSize]=10'
		})
	},
	computed: {
		// 计算属性
	},
	methods: {
		// 方法
    async getData(options){
      let { data } = await getData(options)
      this.viewData = data.data.attributes
    },
		async getInfoList(options){
      let { data } = await getData(options)
      this.infoData = data.data
    }
	},
	mounted() {
		// 页面进入时加载内容
	},
	watch: {
		// 监测变化
	},
};
</script>
<style lang="less">
.block-3 {
	// width: 1200px;
	margin-top: 97px;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	.top {
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		.query-mixin(768px, {
			justify-content: center;
		});
		.title {
			font-size: 48px;
			font-weight: 600;
			color: #fff;
			background: linear-gradient(-23deg, #fff 0, #fff9e5 50%, #ffedb6 100%);
			background-clip: text;
			-webkit-text-fill-color: transparent;
		}
		.btn {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 120px;
			height: 40px;
			background: #3f3f43;
			border-radius: 20px;
			font-size: 16px;
			color: #fff;
			.query-mixin(768px, {
				display: none;
			});
		}
	}
	.des {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		p {
			font-size: 20px;
			font-weight: 400;
			color: #fff;
			line-height: 38px;
		}
	}


}
</style>
