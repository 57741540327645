<template>
  <ul class="nav">
    <!-- <nav-item :nav="item" v-for="item in navs" :key="item.name"></nav-item> -->
    <li class='item' v-for="item in viewData.list.filter((item,index) => index == 0 || index == 3 )" :key="item.id">
      <span class="note" v-if="item.note" :style="{ backgroundImage: 'url(' + viewData.note_icon.data.attributes.url + ')' }">
        {{ item.note }}
      </span>
      <router-link :to="item.link" class="btn" active-class="active" exact :style="{ '--iconurl': `url(' ${iconurl}')` }">{{item.text}}</router-link>
    </li>
  </ul>
</template>

<script>
import { getData } from "@/api/index.js";
export default {
name: 'NavBar',
components: { // 组件的引用

},
data () {
  return {
    iconurl: '',
    viewData: {}
  }
},
created () { // 实例被创建之后执行代码
  this.getData({
    url: 'api/menu?populate=icon&populate=note_icon&populate=list',
  })
},
computed: { // 计算属性

},
methods: { // 方法
  async getData(options) {
    let { data } = await getData(options)
    this.viewData = data.data.attributes
    console.log(this.viewData)
    this.iconurl = this.this.viewData.icon.data.attributes.url
    console.log(this.iconurl);
  }
},
mounted () { // 页面进入时加载内容

},
watch: { // 监测变化

}
}
</script>
<style lang='less'>
.nav {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-shrink: 0;
  margin: 0 90px;
  .query-mixin(768px,{
    flex-direction: column;
    align-items: flex-start;
    margin: 0;
    position: absolute;
    top: 54px;
    right: 0;
    background: rgb(44, 44, 44);
  });
  .item{
    min-width: 37px;
    margin-right: 40px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    font-size: 24px;
    color: #fff;
    position: relative;
    .query-mixin(768px,{
      min-width: 100px;
      padding: 10px;
      text-align: start;
      font-size: 12px;
    });
    &:nth-child(3),&:nth-child(5),&:nth-child(6){
      display: none;
    }
    &:last-child{
      margin-right: 0;
    }
    .note{
      position: absolute;
      top: -20px;
      z-index: 99;
      width: 63px;
      height: 22px;
      background: 0 0/100% no-repeat;
      // background: url(https://www.qiyou.cn/assets/images/common/zhujijiasu.png) 0 0/100% no-repeat;
      font-size: 11px;
      line-height: 18px;
      color: #000;
      text-align: center;
    }
    a{
      &:hover{
        color: @header-color;
      }
      .query-mixin(768px,{
        text-align: start;
      });
    }
  }
  .active{
    color: @header-color;
  }
  // .active::after{
  //   position: absolute;
  //   top: 20px;
  //   content: "";
  //   display: block;
  //   margin-top: 7px;
  //   width: 37px;
  //   height: 8px;
  //   background: var(--iconurl) 0 0/100% no-repeat;
  // }
}
</style>