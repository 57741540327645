import Vue from "vue";
import VueRouter from "vue-router";
import HomePage from "../views/home/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    meta: {
      title: "首页",
    },
    component: HomePage,
  },
  {
    path: "/download",
    name: "download",
    meta: {
      title: "下载",
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "pc" */ "../views/download/Download.vue"),
  },
  {
    path: "/pc",
    name: "pc",
    meta: {
      title: "联机宝",
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "pc" */ "../views/pc/Pc.vue"),
  },
  {
    path: "/mobile",
    name: "mobile",
    meta: {
      title: "手机加速",
    },
    component: () =>
      import(/* webpackChunkName: "mobile" */ "../views/mobile/Mobile.vue"),
  },
  {
    path: "/vip",
    name: "vip",
    meta: {
      title: "会员中心",
    },
    component: () =>
      import(/* webpackChunkName: "vip" */ "../views/vip/Vip.vue"),
  },
  {
    path: "/game",
    name: "game",
    meta: {
      title: "游戏",
    },
    component: () =>
      import(/* webpackChunkName: "game" */ "../views/game/Game.vue"),
  },
  {
    path: "/match",
    name: "match",
    meta: {
      title: "赛事",
    },
    component: () =>
      import(/* webpackChunkName: "match" */ "../views/match/Match.vue"),
  },
  {
    path: "/news",
    name: "newsIndex",
    redirect: { name: "news", params: { id: "0" } },
  },
  {
    path: "/news/:id",
    name: "news",
    meta: {
      title: "资讯",
    },
    component: () =>
      import(/* webpackChunkName: "news" */ "../views/news/News.vue"),
  },
  {
    path: '/article/:id',
    name: "article",
    meta: {
      title: "文章详情",
    },
    component: () =>
      import(/* webpackChunkName: "article" */ "../views/article/Article.vue"),
  },
  {
    path: "/about",
    name: "about",
    meta: {
      title: "关于我们",
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/about/About.vue"),
  },
  {
    path: "/privacy",
    name: "privacy",
    meta: {
      title: "隐私政策",
    },
    component: () =>
      import(/* webpackChunkName: "privacy" */ "../views/privacy/Privacy.vue"),
  },
];
const router = new VueRouter({
  mode: "history",
  // fallback: true,
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to,from,next)=>{//beforeEach是router的钩子函数，在进入路由前执行
  console.log(to);
  if(to.meta.title){//判断是否有标题
      document.title = '泫游' + to.meta.title
  }
  next()  //执行进入路由，如果不写就不会进入目标页
})


export default router;
