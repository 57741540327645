/* 基础公共服务 */

export const baseUrl = {
  dev: 'http://10.151.121.16:1337/',
  test: '/',
  prod: '/'
}

export const baseUrl2 = {
  dev: 'https://je1-accel.cb3dp.com/',
  test: '/',
  prod: 'https://je1-accel.cb3dp.com/'
}

 
/* 服务3 */
//export const baseUrl3 = 'http://..................' , //开发环境
//export const baseUrl3 = 'http://..................' , //综测环境
//export const baseUrl3 = 'http://..................' , //本地联调
 
export default {
    baseUrl,
    baseUrl2,
    // baseUrl3
}