<template>
  <div class="bg-animate">
    <!-- <div class="bg-box" :style="{backgroundImage: 'url('+ viewData.animate.data.attributes.url +')'}"> -->
    <div class="bg-box">
      <div class="bg-item">
        <!-- <img :src="'@/assets/images/animate1.jpg'" alt=""> -->
        <img :src="viewData.animate.data.attributes.url">
      </div>
      <div class="bg-item">
        <img :src="viewData.animate2.data.attributes.url">
      </div>
    </div>
    <div class="mask"></div>
  </div>
</template>

<script>
import { getData } from '@/api/index.js'
export default {
  name: 'BgAnimate',
  data () {
    return {
      viewData: {}
    }
  },
  created () { // 实例被创建之后执行代码
    this.getData({
      url: '/api/home-part-first-info?populate=animate&populate=animate2',
    })
  },
  computed: { // 计算属性

  },
  components: { // 组件的引用

  },
  methods: { // 方法
    async getData(options){
      let { data } = await getData(options)
      this.viewData = data.data.attributes
    }
  },
  mounted () { // 页面进入时加载内容
  },
  watch: { // 监测变化

  }
}
</script>
<style lang='less'>
.bg-animate {
  position: absolute;
  top: 90px;
  z-index: 1;
  // width: 1920px;
  width: 100%;
  height: 700px;
  overflow: hidden;
  .query-mixin(768px, {
    // header的高度由90px变为54px，所以这里的top值也要变为54px
    top: 54px;
    height: 398px;
  });
  .bg-box {
    // width: 1920px;
    // position: absolute;
    // left: -50%;
    // transform: translateX(50%);
    width: 1920px;
    height: 2160px;
    background: 
      0 0/100% 100% no-repeat;
    animation: 80s linear infinite homeBgAnimation;
    display: flex;
    flex-wrap: wrap;
    // .query-mixin(768px, {
    //   width: 100%;
    //   height: 400px;
    //   animation: 80s linear infinite mobileHomeBgAnimation;
    // })
    .bg-item{
      position: relative;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  .mask {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    height: 2260px;
    // background: linear-gradient(
    //   0deg,
    //   #131319 0,
    //   rgba(19, 19, 25, 0.7) 60%,
    //   rgba(19, 19, 25, 0) 100%
    // );
    background: linear-gradient(
      0deg,
      #131319 0,
      rgba(19, 19, 25, 0.7) 100%,
    );
    .query-mixin(768px, {
      width: 100%;
      height: 400px;
    })
  }
}

@keyframes homeBgAnimation{
  0%{
    transform: translateZ(0);
  }
  100%{
    transform: translate3d(0,-1500px,0);
  }
}
@keyframes mobileHomeBgAnimation{
  0%{
    transform: translateZ(0);
  }
  100%{
    transform: translate3d(0,-464px,0);
  }
}
</style>