<template>
  <div id='app'>
    <keep-alive>
      <header-view v-if="header_show"></header-view>
    </keep-alive>
    <router-view v-on:header= "header"  v-on:footer= "footer" ></router-view>
    <keep-alive>
      <footer-view v-if="footer_show"></footer-view>
    </keep-alive>
  </div>
</template>

<script>
import HeaderView from "@/components/header/Header.vue";
import FooterView from "@/components/footer/Footer.vue";
export default {
  name: 'App',
  components: { // 组件的引用
    HeaderView,
    FooterView
  },
  data () {
    return {
      header_show: true,
      footer_show: true,
    }
  },
  created () { // 实例被创建之后执行代码

  },
  computed: { // 计算属性

  },
  methods: { // 方法
    header(data) {
      this.header_show = data
    },
    footer(data) {
      this.footer_show = data
    }
  },
  mounted () { // 页面进入时加载内容

  },
  watch: { // 监测变化

  }
}
</script>
<style lang='less'>

</style>
