<template>
  <div class="page-wrapper">
			<div class="page-inner-wrapper">
        <bg-animate></bg-animate>
				<div class="cover">
          <block-one></block-one>
          <block-two></block-two>
          <block-three></block-three>
				</div>
			</div>
		</div>
</template>

<script>
import BgAnimate from "./components/BgAnimate.vue";
import BlockOne from "./components/BlockOne.vue";
import BlockTwo from "./components/BlockTwo.vue";
import BlockThree from "./components/BlockThree.vue";
export default {
	name: "HomePage",
	components: {
    BgAnimate,
    BlockOne,
    BlockTwo,
    BlockThree,
	},
	data() {
		return {};
	},
	created() {
		// 实例被创建之后执行代码
	},
	computed: {
		// 计算属性
	},
	methods: {
		// 方法
	},
	mounted() {
		// 页面进入时加载内容
	},
	watch: {
		// 监测变化
	},
};
</script>
<style lang="less">
	.cover {
		width: 100%;
		height: auto;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
	}
</style>
