<template>
  <div id="footer">
    <div class="margin">
      <div class="footer-top">
        <div class="download">
          <div class="title">{{ viewData.download.title }}</div>
          <a class="item" v-for="item in viewData.download.list" :key="item.id" :href="item.link">
            <i v-for="icon in item.icon_list"
              :style="{ backgroundImage: 'url(' + icon.icon.data.attributes.url + ')' }" :key="icon.id"></i>
            {{ item.text }}
          </a>
        </div>
        <div class="link">
          <div class="column" v-for="link in viewData.link" :key="link.id">
            <div class="title">{{ link.title }}</div>
            <div class="list">
              <a class="item" v-for="item in link.list" :key="item.id" :href="item.link">{{ item.text }}</a>
            </div>
          </div>
        </div>
        <div class="contact">
          <a class="service" v-for="item in viewData.contact_service" :key="item.id" @click="openQQ">
            <i :style="{ backgroundImage: 'url(' + item.icon.data.attributes.url + ')' }"></i>
            {{ item.text }}
          </a>
          <div class="schedule">
            {{ viewData.contact_schedule }}
          </div>
          <div class="platform">
            <platform-item v-for="item in viewData.service_platform" :key="item.id" :platform="item"></platform-item>
          </div>
        </div>
      </div>
      <div class="footer-middle">
        <div class="friends" v-for="friend in viewData.friends" :key="friend.id">
          <span>{{ friend.title }}</span>
          <a :href="item.link" class="item" v-for="item in friend.list" :key="item.id">{{ item.text }}</a>
        </div>
        <div class="address">
          <span class="item" v-for="item in viewData.address" :key="item.id">{{ item.text }}</span>
        </div>
      </div>
      <div class="gap"></div>
      <div class="footer-bottom">
        <div class="left">
          <div class="wrap">
            <div v-for="item in viewData.copyright_first_line" :key="item.id" class="item">
              <a v-if="item.link" :href="item.link">
                <i v-if="item.icon.data"
                  :style="{ backgroundImage: 'url(' + item.icon.data.attributes.url + ')' }"></i>
                {{ item.text }}
              </a>
              <span v-else>{{ item.text }}</span>
            </div>
          </div>
          <div class="wrap">
            <div v-for="item in viewData.copyright_second_line" :key="item.id" :href="item.link">
              <a v-if="item.link" :href="item.link">{{ item.text }}</a>
              <span v-else>{{ item.text }}</span>
            </div>
          </div>
        </div>
        <!-- <div class="right">
          <div :style="{ backgroundImage: 'url(' + viewData.copyright_right.data.attributes.url + ')' }"></div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { getData } from '@/api/index.js'
import PlatformItem from '@/components/footer/PlatformItem.vue'
export default {
  name: "FooterView",
  data() {
    return {
      viewData: {},
    };
  },
  created() {
    // 实例被创建之后执行代码
    this.getData({
      url: '/api/footer?populate=download&populate=download.list&populate=download.list.icon_list&populate=download.list.icon_list.icon&populate=link&populate=link.list&populate=contact_service&populate=service_platform&populate=service_platform.icon&populate=service_platform.icon_active&populate=service_platform.code_img&populate=friends&populate=friends.list&populate=address&populate=copyright_first_line&populate=copyright_first_line.icon&populate=contact_service&populate=contact_service.icon',
    })
  },
  computed: {
    // 计算属性
  },
  components: {
    // 组件的引用
    PlatformItem
  },
  methods: {
    // 方法
    async getData(options) {
      let { data } = await getData(options)
      this.viewData = data.data.attributes
      console.log(this.viewData)
    },
    openQQ() {
      // window.open('http://wpa.qq.com/msgrd?v=3&uin=1179814836&site=qq&menu=yes');
    }
  },
  mounted() {
    // 页面进入时加载内容
  },
  watch: {
    // 监测变化
  },
};
</script>
<style lang="less" scoped>
#footer {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  height: 480px;
  background: #212127;
  .query-mixin(768px, {
    height: auto;
  });
  .margin {
    width: 1200px;
    height: 100%;
    background: #212127;
    .query-mixin(1200px,{
      width: 100%;
    });
    .footer-top {
      display: flex;
      justify-content: space-between;
      margin: 0 30px;
      .query-mixin(768px,{
        flex-direction: column;
        align-items: center;
      });
      .download {
        padding-top: 76px;
        display: flex;
        flex-direction: column;
        .query-mixin(768px,{
          display: none;
        });
        .title {
          font-size: 34px;
          font-weight: 700;
          color: @header-color;
          padding-bottom: 18px;
          line-height: 34px;
          // .query-mixin(992px,{
          //   display: none;
          // });
        }

        .item {
          display: flex;
          align-items: center;
          margin-top: 10px;
          padding-left: 24px;
          width: 150px;
          height: 42px;
          font-size: 16px;
          color: #fff;
          line-height: 16px;
          border: 1px solid #343437;
          border-radius: 21px;

          i {
            width: 19px;
            height: 19px;
            margin-right: 17px;
            background-repeat: no-repeat;
            background-size: 100% 100%;
          }

          /* 第二个 .item 下 i 的单独样式 */
          &:nth-child(3) {
            i {
              margin-right: 8px;
            }
          }
        }
      }

      .link {
        padding-top: 91px;
        display: flex;
        .query-mixin(768px,{
          flex-direction: column;
          padding-top: 30px;
        });
        .column {
          margin-right: 99px;
          display: flex;
          flex-direction: column;
          .query-mixin(992px,{
            margin-right: 50px;
          });
          .query-mixin(768px,{
            flex-direction: row;
            align-items: center;
            padding: 10px 0;
            margin-right: 0;
          });
          .title {
            font-size: 16px;
            font-weight: 700;
            color: @header-color;
            line-height: 16px;
            .query-mixin(768px,{
              margin-right: 20px;
            });
          }

          .list {
            margin-top: 25px;
            display: flex;
            flex-direction: column;
            .query-mixin(768px,{
              flex-direction: row;
              margin-top: 0;
            });
            a {
              margin-top: 19px;
              font-size: 16px;
              line-height: 16px;
              color: @theme-font-color;
              .query-mixin(768px,{
                margin-top: 0;
                margin-right: 10px;
                font-size: 14px;
              });
            }
          }
        }
      }

      .contact {
        padding-top: 73px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        .query-mixin(768px,{
          padding-top: 20px;
        });
        .service {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          width: 128px;
          height: 40px;
          font-size: 16px;
          color: #9c9c9e;
          line-height: 16px;
          background: #3f3f43;
          border-radius: 20px;

          i {
            width: 22px;
            height: 22px;
            margin: 0 13px 0 16px;
            background-repeat: no-repeat;
            background-size: 100% 100%;
          }
        }

        .schedule {
          margin-top: 11px;
          font-size: 12px;
          line-height: 12px;
          color: #8d8d8f;
          .query-mixin(768px,{
            display: none;
          });
        }

        .platform {
          margin-top: 22px;
          display: flex;
          .query-mixin(768px,{
            display: none;
          });
          .item {
            margin-right: 29px;
            background-repeat: no-repeat;
            background-size: 100% 100%;
            position: relative;
            width: 25px;
            height: 25px;

            &:last-child {
              margin-right: 0;
            }

            &:hover {
              /deep/.code-content {
                display: flex;
              }
            }

            /deep/.code-content {
              box-sizing: border-box;
              padding: 20px 20px 15px;
              background-color: #fff;
              border: 1px solid #ececec;
              border-radius: 3px;
              text-align: center;
              position: absolute;
              left: 50%;
              transform: translateX(-50%);
              top: -200px;
              font-size: 12px;
              color: #202020;
              justify-content: space-between;
              display: none;

              &:after {
                content: "";
                width: 0;
                height: 0;
                border: 8px solid transparent;
                border-top-color: #fff;
                position: absolute;
                bottom: -15px;
                left: 50%;
                margin-left: -8px;
              }

              .code-item {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;

                .code-title {
                  margin-top: 10px;
                }

                .code-img {
                  width: 120px;
                  height: 120px;
                }
              }
            }
          }
        }
      }
    }

    .footer-middle {
      position: relative;
      margin-top: 60px;
      display: flex;
      justify-content: space-between;
      .query-mixin(768px,{
        flex-direction: column;
        align-items: center;
        margin-top: 20px;
      });
      .friends {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-left: 30px;
        height: 16px;
        .query-mixin(768px,{
          margin-left: 0;
        });
        span {
          font-size: 16px;
          color: #fff;
          line-height: 16px;
        }

        .item {
          padding-left: 26px;
          font-size: 14px;
          color: @theme-font-color;
          line-height: 14px;
        }
      }

      .address {
        font-size: 12px;
        font-family: PingFang SC;
        font-weight: 300;
        color: #9c9c9e;
        line-height: 12px;
        .query-mixin(768px,{
          display: none;
          margin-top: 20px;
        });
      }
    }

    .gap {
      margin-top: 18px;
      width: 1200px;
      height: 1px;
      background: #2e2e34;
      .query-mixin(1200px,{
        width: 100%;
      });
      .query-mixin(768px,{
        display: none;
      });
    }

    .footer-bottom {
      margin: 10px 30px 0;
      display: flex;
      justify-content: center;

      .left {
        .wrap {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 6px;
          font-size: 12px;
          color: #9c9c9e;

          .item {
            display: flex;
            align-items: center;

            // &:last-child {
            //   margin-left: 56px;
            // }

            a {
              display: flex;
            align-items: center;
              i {
                margin-right: 5px;
                width: 18px;
                height: 20px;
                background-repeat: no-repeat;
                background-size: 100% 100%;
              }
            }
          }
        }

      }

      .right {
        div {
          width: 88px;
          height: 30px;
          margin-top: 22px;
          background-repeat: no-repeat;
          background-size: 100% 100%;
        }
      }
    }
  }
}
</style>
